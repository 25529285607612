import { parse, stringify, ParsedUrlQueryInput } from "querystring";
import { omitBy, isNil } from "lodash-es";
import { History, Location as HistoryLocation } from "history";

type UpdateQSProps = {
  history: History;
  location: HistoryLocation;
  update: ParsedUrlQueryInput;
  qs?: ParsedUrlQueryInput;
  method?: "push" | "replace";

  /**
   * Whether the updated querystring should drop keys whose value is null,
   * undefined, or an empty string.
   */
  omitEmpty?: boolean;
};

interface FormatFullAddressOptions {
  street_address_1?: string | null;
  street_address_2?: string | null;
  city?: string | null;
  state?: string;
  zip?: string;
  country?: string;
}

// Formats the address in a single line
const formatFullAddress = (address: FormatFullAddressOptions) => {
  let fullAddress = "";
  address = sanitizeAddress(address);

  fullAddress += formatAddressStreet(address);
  fullAddress += formatAddressCity(address);
  fullAddress += formatAddressStateAndZip(address);
  fullAddress += formatAddressCountry(address);

  return fullAddress;
};

const formatMultilineAddress = (address: FormatFullAddressOptions) => {
  let fullAddress = "";
  address = sanitizeAddress(address);

  fullAddress += formatAddressStreet(address, "\n");
  fullAddress += formatAddressCity(address);
  fullAddress += formatAddressStateAndZip(address, "\n");
  fullAddress += formatAddressCountry(address);

  return fullAddress;
};

const sanitizeAddress = (
  address: FormatFullAddressOptions
): FormatFullAddressOptions => {
  return {
    street_address_1: address.street_address_1?.trim() || "",
    street_address_2: address.street_address_2?.trim() || "",
    city: address.city?.trim() || "",
    state: address.state?.trim() || "",
    zip: address.zip?.trim() || "",
    country: address.country?.trim() || "",
  };
};

const formatAddressStreet = (
  { street_address_1, street_address_2 }: FormatFullAddressOptions,
  lineEnding = ", "
) =>
  street_address_1 || street_address_2
    ? `${street_address_1} ${street_address_2}`.trim() + lineEnding
    : "";
const formatAddressCity = ({ city }: FormatFullAddressOptions) =>
  city ? `${city}, ` : "";
const formatAddressStateAndZip = (
  { state, zip }: FormatFullAddressOptions,
  lineEnding = ", "
) => (state || zip ? `${state} ${zip}`.trim() + lineEnding : "");
const formatAddressCountry = ({ country }: FormatFullAddressOptions) =>
  country ? country : "";

const formatRate = (value: number, period: string = "mo") => {
  const currency = formatCurrency(value);
  return `${currency} /${period}`;
};

const formatCurrency = (number: number, currency = "USD", locale = "en-US") => {
  return Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(number);
};

const formatName = (firstName: string, lastName: string, defaultEmpty = "") => {
  return firstName || lastName
    ? `${firstName} ${lastName}`.trim()
    : defaultEmpty;
};

// Given a string over 4 characters long, return the same string broken up into chunks of 3 or less.
// "0000000000" => "0 000 000 000"
// "000000" => "000 000"
// "0000" => "0000"
const formatAccessCode = (code: string) =>
  code.length > 4 ? code.replace(/\B(?=(?:\d{3})+(?!\d))/g, " ") : code;

export default {
  // Given this.props.location, return an object representing the query string
  qsFromLocation(location: Location | HistoryLocation) {
    return location && location.search
      ? parse(location.search.replace("?", ""))
      : {};
  },

  /**
   * Sample usage:
   *
   * Helpers.updateQS({
   *  history,
   *  update: { marketing_names },
   *  location,
   *  qs,
   * })
   */
  updateQS({
    history,
    update,
    location,
    qs,
    method = "push",
    omitEmpty = false,
  }: UpdateQSProps) {
    const mergedQs = { ...qs, ...update };
    const updatedQs = omitEmpty
      ? omitBy(mergedQs, (v) => isNil(v) || v === "")
      : mergedQs;

    return history[method](
      `${location.pathname}${
        Object.keys(updatedQs).length ? `?${stringify(updatedQs)}` : ""
      }`
    );
  },

  // Convert an object into a querystring, you will still need to prepend `?` to the qs
  toQS(filters: ParsedUrlQueryInput) {
    return stringify(filters);
  },

  snakeToTitleCase: (str: string) =>
    str
      .split("_")
      .map((s) => `${s.charAt(0).toUpperCase()}${s.substring(1)}`)
      .join(" "),

  formatAccessCode,
  formatCurrency,
  formatRate,
  formatName,
  formatFullAddress,
  formatMultilineAddress,
};
